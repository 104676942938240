

























































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConfigureRank extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  value!: any;
  @Prop({
    default: () => {
      return [];
    },
  })
  series!: string[];
  @Prop({
    default: () => {
      return [];
    },
  })
  procedures!: string[];
  @Prop({
    default: () => {
      return [];
    },
  })
  exams!: string[];

  object = { name: '', count: 0 };
  dialogVisible = false;

  examAdd() {
    if (this.object.name.length > 0 && this.object.count > 0) {
      this.value.exam.push(this.object);
    }
    this.object = { name: '', count: 0 };
    this.dialogVisible = false;
  }

  examRemove(tag: any) {
    this.value.exam.splice(this.value.exam.indexOf(tag), 1);
  }

  handleClose() {
    this.object = { name: '', count: 0 };
    this.dialogVisible = false;
  }
}
