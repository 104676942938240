







































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Message } from 'element-ui';
import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Tinymce from '@/components/Tinymce/index.vue';
import { db } from '@/firebase';
import ConfigureRank from './components/ConfigureRank.vue';
import { has, uniq, chunk, clone } from 'lodash';
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import { getElfConfig, saveElfConfig } from '@/firebase/models/elf';
import { IRemoteConfig } from '@/types';

@Component({
  components: {
    ConfigureRank,
    Tinymce,
  },
})
export default class LearningFeatureConfiguration extends Vue {
  saving = false;
  activeAccordion = '1';
  labels = {};
  examsSergeant: any = [];
  examsLieutenant: any = [];
  examsCaptain: any = [];
  tags: string[] = [];
  currentLabels: string[] = [];
  series: { sergeant: string[]; lieutenant: string[]; captain: string[] } = {
    sergeant: [],
    lieutenant: [],
    captain: [],
  };
  procedures: { sergeant: string[]; lieutenant: string[]; captain: string[] } = {
    sergeant: [],
    lieutenant: [],
    captain: [],
  };
  data: any = {
    about: '',
    captainExam: [],
    captainExamEnabled: false,
    captainQuarterlyExams: [],
    captainQuarterlyExamsEnabled: false,
    captainQuizzes: [],
    captainQuizzesProcedures: [],
    captainQuizzesEnabled: false,
    guestQuizzesSeries: [],
    guestQuizzesProcedures: [],
    labels: {},
    lieutenantExam: [],
    lieutenantExamEnabled: false,
    lieutenantQuarterlyExams: [],
    lieutenantQuarterlyExamsEnabled: false,
    lieutenantQuizzes: [],
    lieutenantQuizzesProcedure: [],
    lieutenantQuizzesEnabled: false,
    questionRetrievalHours: 0,
    questionRetrievalMinutes: 0,
    sergeantExam: [],
    sergeantExamEnabled: false,
    sergeantQuarterlyExams: [],
    sergeantQuarterlyExamsEnabled: false,
    sergeantQuizzes: [],
    sergeantQuizzesProcedures: [],
    sergeantQuizzesEnabled: false,
  };
  sergeantConfig: any = {
    exam: [],
    examEnabled: false,
    quarterlyExams: [],
    quarterlyExamsEnabled: false,
    quizzes: [],
    quizzesProcedures: [],
    quizzesEnabled: false,
  };
  lieutenantConfig: any = {
    exam: [],
    examEnabled: false,
    quarterlyExams: [],
    quarterlyExamsEnabled: false,
    quizzes: [],
    quizzesProcedures: [],
    quizzesEnabled: false,
  };
  captainConfig: any = {
    exam: [],
    examEnabled: false,
    quarterlyExams: [],
    quarterlyExamsEnabled: false,
    quizzes: [],
    quizzesProcedures: [],
    quizzesEnabled: false,
  };

  labelChange(value: string[]) {
    const newValue: { [x: string]: string } = {};
    for (const item of value) {
      newValue[item] = this.data.labels[item] || '';
    }
    this.data.labels = newValue;
  }

  save() {
    this.saving = true;
    saveElfConfig(this.data)
      .then(() => {
        Message({
          type: 'success',
          message: 'Configuration saved',
        });
      })
      .catch(() => {
        Message({
          type: 'error',
          message: 'An error occurred, please try again later',
        });
      })
      .then(() => (this.saving = false));
  }

  created() {
    db.collection('Overview')
      .doc('LearningFeature')
      .get()
      .then((document: DocumentSnapshot) => {
        const data = document.data() || {};
        this.series = {
          sergeant: Object.keys(data.sergeant.series).map((k) => k) || [],
          lieutenant: Object.keys(data.lieutenant.series).map((k) => k) || [],
          captain: Object.keys(data.captain.series).map((k) => k) || [],
        };
        this.procedures = {
          sergeant: Object.keys(data.sergeant.procedures).map((k) => k) || [],
          lieutenant: Object.keys(data.lieutenant.procedures).map((k) => k) || [],
          captain: Object.keys(data.captain.procedures).map((k) => k) || [],
        };
        this.tags = uniq(
          [
            ...(Object.keys(data.sergeant.series).map((k) => k) || []),
            ...(Object.keys(data.lieutenant.series).map((k) => k) || []),
            ...(Object.keys(data.captain.series).map((k) => k) || []),
            ...(Object.keys(data.sergeant.procedures).map((k) => k) || []),
            ...(Object.keys(data.lieutenant.procedures).map((k) => k) || []),
            ...(Object.keys(data.captain.procedures).map((k) => k) || []),
          ].sort(),
        );
      });
    db.collection('LearningFeatureExams')
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = clone(doc.data());
          const examItem: any = {};
          examItem.id = doc.id;
          examItem.name = data.name;
          examItem.series = data.series;
          examItem.count = data.ids.length;
          if (data.class === 'captain') {
            this.examsCaptain.push(examItem);
          } else if (data.class === 'lieutenant') {
            this.examsLieutenant.push(examItem);
          } else {
            this.examsSergeant.push(examItem);
          }
        });
      });
    getElfConfig().then((results: any) => {
      this.data = {
        about: results.about.defaultValue.value,
        captainExam: JSON.parse(results.captainExam.defaultValue.value),
        captainExamEnabled: results.captainExamEnabled.defaultValue.value === 'true',
        captainQuarterlyExams: JSON.parse(results.captainQuarterlyExams.defaultValue.value || '[]'),
        captainQuarterlyExamsEnabled: results.captainQuarterlyExamsEnabled.defaultValue.value === 'true',
        captainQuizzes: JSON.parse(results.captainQuizzes.defaultValue.value),
        captainQuizzesProcedures: JSON.parse(results.captainQuizzesProcedures.defaultValue.value),
        captainQuizzesEnabled: results.captainQuizzesEnabled.defaultValue.value === 'true',
        guestQuizzesSeries: JSON.parse(results.guestQuizzesSeries.defaultValue.value),
        guestQuizzesProcedures: JSON.parse(results.guestQuizzesProcedures.defaultValue.value),
        labels: JSON.parse(results.labels.defaultValue.value),
        lieutenantExam: JSON.parse(results.lieutenantExam.defaultValue.value),
        lieutenantExamEnabled: results.lieutenantExamEnabled.defaultValue.value === 'true',
        lieutenantQuarterlyExams: JSON.parse(results.lieutenantQuarterlyExams.defaultValue.value),
        lieutenantQuarterlyExamsEnabled: results.lieutenantQuarterlyExamsEnabled.defaultValue.value === 'true',
        lieutenantQuizzes: JSON.parse(results.lieutenantQuizzes.defaultValue.value),
        lieutenantQuizzesProcedure: JSON.parse(results.lieutenantQuizzesProcedures.defaultValue.value || '[]'),
        lieutenantQuizzesEnabled: results.lieutenantQuizzesEnabled.defaultValue.value === 'true',
        questionRetrievalHours: parseInt(results.questionRetrievalHours.defaultValue.value, 10),
        questionRetrievalMinutes: parseInt(results.questionRetrievalMinutes.defaultValue.value, 10),
        sergeantExam: JSON.parse(results.sergeantExam.defaultValue.value),
        sergeantExamEnabled: results.sergeantExamEnabled.defaultValue.value === 'true',
        sergeantQuarterlyExams: JSON.parse(results.sergeantQuarterlyExams.defaultValue.value),
        sergeantQuarterlyExamsEnabled: results.sergeantQuarterlyExamsEnabled.defaultValue.value === 'true',
        sergeantQuizzes: JSON.parse(results.sergeantQuizzes.defaultValue.value),
        sergeantQuizzesProcedures: JSON.parse(results.sergeantQuizzesProcedures.defaultValue.value || '[]'),
        sergeantQuizzesEnabled: results.sergeantQuizzesEnabled.defaultValue.value === 'true',
      };
      this.currentLabels = Object.keys(this.data.labels || {});
      this.sergeantConfig = {
        exam: this.data.sergeantExam,
        examEnabled: this.data.sergeantExamEnabled,
        quarterlyExams: this.data.sergeantQuarterlyExams,
        quarterlyExamsEnabled: this.data.sergeantQuarterlyExamsEnabled,
        quizzes: this.data.sergeantQuizzes,
        quizzesProcedures: this.data.sergeantQuizzesProcedures,
        quizzesEnabled: this.data.sergeantQuizzesEnabled,
      };
      this.lieutenantConfig = {
        exam: this.data.lieutenantExam,
        examEnabled: this.data.lieutenantExamEnabled,
        quarterlyExams: this.data.lieutenantQuarterlyExams,
        quarterlyExamsEnabled: this.data.lieutenantQuarterlyExamsEnabled,
        quizzes: this.data.lieutenantQuizzes,
        quizzesProcedures: this.data.lieutenantQuizzesProcedures,
        quizzesEnabled: this.data.lieutenantQuizzesEnabled,
      };
      this.captainConfig = {
        exam: this.data.captainExam,
        examEnabled: this.data.captainExamEnabled,
        quarterlyExams: this.data.captainQuarterlyExams,
        quarterlyExamsEnabled: this.data.captainQuarterlyExamsEnabled,
        quizzes: this.data.captainQuizzes,
        quizzesProcedures: this.data.captainQuizzesProcedures,
        quizzesEnabled: this.data.captainQuizzesEnabled,
      };
    });
  }

  @Watch('sergeantConfig', { deep: true })
  sergeantChange(value: any) {
    this.data = {
      ...this.data,
      sergeantExam: value.exam,
      sergeantExamEnabled: value.examEnabled,
      sergeantQuarterlyExams: value.quarterlyExams,
      sergeantQuarterlyExamsEnabled: value.quarterlyExamsEnabled,
      sergeantQuizzes: value.quizzes,
      sergeantQuizzesProcedures: value.quizzesProcedures,
      sergeantQuizzesEnabled: value.quizzesEnabled,
    };
  }

  @Watch('lieutenantConfig', { deep: true })
  lieutenantChange(value: any) {
    this.data = {
      ...this.data,
      lieutenantExam: value.exam,
      lieutenantExamEnabled: value.examEnabled,
      lieutenantQuarterlyExams: value.quarterlyExams,
      lieutenantQuarterlyExamsEnabled: value.quarterlyExamsEnabled,
      lieutenantQuizzes: value.quizzes,
      lieutenantQuizzesProcedures: value.quizzesProcedures,
      lieutenantQuizzesEnabled: value.quizzesEnabled,
    };
  }

  @Watch('captainConfig', { deep: true })
  captainChange(value: any) {
    this.data = {
      ...this.data,
      captainExam: value.exam,
      captainExamEnabled: value.examEnabled,
      captainQuarterlyExams: value.quarterlyExams,
      captainQuarterlyExamsEnabled: value.quarterlyExamsEnabled,
      captainQuizzes: value.quizzes,
      captainQuizzesProcedures: value.quizzesProcedures,
      captainQuizzesEnabled: value.quizzesEnabled,
    };
  }
}
